import React, { useEffect } from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import { colors } from '../styles/theme';
import { storeReferralData } from '../utils/helpers';
import SEO from '../components/SEO';
import { sponsorContent } from '../utils/dataConfig';
import SponsorForm from '../components/forms/SponsorForm';
import {
  ModalContentContainer,
} from '../styles/shared';

const SponsorPage = ({ location }) => {
  // store referral params
  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      storeReferralData(params);
    }
  }, []);
  return (
    <>
      <SEO title='Help house the team' />
      <Layout>
        <Container>
          <OwnerModalContent>
            <h1>{sponsorContent.heading}</h1>
            <SponsorForm />
          </OwnerModalContent>
        </Container>
      </Layout>
    </>
  );
};
const Container = styled.section`
  padding: var(--body-padding-y) var(--body-padding-x);
  a:hover {
    color: ${colors.blue};
    text-decoration: underline;
  }
`;
const OwnerModalContent = styled(ModalContentContainer)`
  row-gap: 1.5rem;
`;

export default SponsorPage;
