import React from 'react';
import styled from 'styled-components';
import { IoIosCheckbox } from '@react-icons/all-files/io/IoIosCheckbox';
import { colors, devices } from '../styles/theme';

const Checkbox = ({ isChecked, handleClick, children }) => {
  return (
    <Container onClick={handleClick}>
      {isChecked ? (
        <IoIosCheckbox
          color={colors.blue}
          style={{
            width: 'var(--checkbox-size)',
            height: 'var(--checkbox-size)',
          }}
        />
      ) : (
        <Unchecked className='unchecked' />
      )}
      <Text>{children}</Text>
    </Container>
  );
};
const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  column-gap: 1rem;
  align-items: center;
  justify-content: start;
  cursor: pointer;
  &:hover {
    div.unchecked {
      border-color: ${colors.dark};
    }
  }
  @media (${devices.s}) {
    column-gap: 0.5rem;
  }
`;
const Unchecked = styled.div`
  border-radius: 0.25rem;
  width: var(--checkbox-size);
  height: var(--checkbox-size);
  border: 1px solid ${colors.gray};
`;
const Text = styled.div`
  font-size: 1rem;
  line-height: 2rem;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  column-gap: 0.5rem;
`;
export default Checkbox;
