import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { colors } from '../../styles/theme';
import { getSubmittedForms, getVisitorInfo } from '../../store/app';
import useForm from '../../utils/useForm';
import { formatPrice, validateForm } from '../../utils/helpers';
import { submitSponsor } from '../../utils/api';
import {
  Form,
  FormActionButton,
  FormAgreementContainer,
  FormDueToday,
  FormFootNote,
  FormPricing,
  Input,
  InputGroup,
  ThankYou,
} from '../../styles/shared';
import { sponsorContent } from '../../utils/dataConfig';
import { fontWeights } from '../../styles/theme';
import Checkbox from '../Checkbox';

const formName = 'sponsor';

const SponsorForm = () => {
  // const [isBreakdownOpen, setBreakdownOpen] = useState(false);
  const [agreement, setAgreement] = useState({ show: false, checked: false });
  const handleAgreementCheckboxClick = e => {
    e.stopPropagation();
    setAgreement(prev => ({ ...prev, checked: !prev.checked }));
  };
  const handleShowAgreementOpenClick = e => {
    e.stopPropagation();
    setAgreement(prev => ({ ...prev, show: !prev.show }));
  };
  const [isSubmitting, setSubmitting] = useState(false);
  const visitorInfo = useSelector(getVisitorInfo);
  const { firstName, lastName, email, phone, quantity } = visitorInfo;
  const formSubmitted = useSelector(getSubmittedForms).some(
    f => f === formName
  );
  // const selectedLot = useSelector(getSelectedLot);
  // const selectedCommunity = useSelector(getSelectedCommunity);
  // const downPaymentTotal = useSelector(getTotalDownPayment);
  // const totalPrice = useSelector(getTotalPrice);
  const totalDueToday = 250;

  const { values, handleChange, handleBlur, handleSubmit, errors } = useForm(
    formName,
    // visitorInfo,
    { firstName, lastName, email, phone, quantity },
    validateForm,
    isSubmitting,
    submitting => setSubmitting(submitting),
    submitSponsor
  );
  return (
    <>
      <Form onSubmit={handleSubmit}>
        {formSubmitted ? (
          <ThankYou>{sponsorContent.thankYouMessage}</ThankYou>
        ) : (
          <>
            <InputGroup>
              <p>First name</p>
              <Input
                type='text'
                name='firstName'
                onChange={handleChange}
                value={values.firstName}
                onBlur={handleBlur}
                required
                className={!!errors.firstName ? 'error' : ''}
              />
              <p className='error'>{errors.firstName}</p>
            </InputGroup>
            <InputGroup>
              <p>Last name</p>
              <Input
                type='text'
                name='lastName'
                onChange={handleChange}
                value={values.lastName}
                onBlur={handleBlur}
                required
                className={!!errors.lastName ? 'error' : ''}
              />
              <p className='error'>{errors.lastName}</p>
            </InputGroup>
            <InputGroup>
              <p>Email address</p>
              <Input
                type='email'
                name='email'
                onChange={handleChange}
                value={values.email}
                onBlur={handleBlur}
                required
                className={!!errors.email ? 'error' : ''}
              />
              <p className='error'>{errors.email}</p>
            </InputGroup>
            <InputGroup>
              <p>Phone number</p>
              <Input
                type='tel'
                name='phone'
                onChange={handleChange}
                value={values.phone}
                onBlur={handleBlur}
                className={!!errors.phone ? 'error' : ''}
              />
              <p className='error'>{errors.phone}</p>
            </InputGroup>
            <InputGroup>
              <p>Number of homes to sponsor</p>
              <select
                style={{borderRadius: '0.375rem', borderColor: colors.gray}}
                name='quantity'
                onChange={handleChange}
                value={values.quantity || 1}
                onBlur={handleBlur}
                className={!!errors.quantity ? 'error' : ''}
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
              </select>
            </InputGroup>
            <PricingArea>
              <div>
                <p className='note'>
                  Your fully-refundable deposit holds your sponsor reservation in the order it was received.
                  A Neu Team member will reach out to you to confirm your sponsorship and answer any questions.
                  You will be able to edit or change your Neu Home sponsorships before final confirmation.
                </p>
              </div>
              <FormDueToday>
                <h2>DUE TODAY</h2>
                <h1>{formatPrice((values.quantity || 1) * totalDueToday)}</h1>
              </FormDueToday>
            </PricingArea>
            <FormAgreementContainer>
              <Checkbox
                isChecked={agreement.checked}
                handleClick={handleAgreementCheckboxClick}
              >
                <p>
                  I agree to the{' '}
                  <button type='button' onClick={handleShowAgreementOpenClick}>
                    Neu Pre-Order Agreement
                  </button>
                </p>
              </Checkbox>
              {agreement.show && (
                <p className='agreement-text'>
                  I, (We) hereby deposit {formatPrice((values.quantity || 1) * totalDueToday)} to assure priority for unit
                  selection and membership application in Neu Community, a stock
                  cooperative. I (We) understand that this Reservation Agreement
                  constitutes assurance of priority consideration only in
                  accordance with the reservation number given upon receipt and
                  is not an agreement on my (our) part to complete all
                  membership requirements. I (We) understand that the
                  reservation process is the first part of becoming an owner at
                  Neu Community and that additional qualifying requirements must
                  be met. If I (we) do not wish to retain this reservation
                  priority, my (our) deposit is fully refundable upon written
                  request, and understand my (our) priority is then void. The
                  reservation deposit is non-interest bearing and will be
                  applied to the Share Payment at the time the Subscription
                  Agreement is signed.
                </p>
              )}
            </FormAgreementContainer>
            <FormActionButton
              type='submit'
              disabled={
                isSubmitting ||
                Object.keys(errors).length !== 0 ||
                !agreement.checked
              }
            >
              {isSubmitting ? 'Loading Payment Page...' : 'Pay Reservation Fee'}
            </FormActionButton>
            {errors.submission && (
              <p className='error' style={{ gridColumn: '1 / -1' }}>
                {errors.submission}
              </p>
            )}
          </>
        )}
      </Form>
      <FormFootNote>
        By clicking "Pay Reservation Fee" I agree to the{' '}
        <a href='/terms/' target='_blank'>
          Terms and Conditions
        </a>
        , the{' '}
        <a href='/privacy/' target='_blank'>
          Customer Privacy Agreement
        </a>
        , and consent to be contacted at the number provided with more
        information or offers about Neu products. I understand my consent to be
        contacted is not a condition of purchase.
      </FormFootNote>
    </>
  );
};
const PricingArea = styled(FormPricing)`
  column-gap: 0;
  .total {
    margin-bottom: 1rem;
    font-weight: ${fontWeights.medium};
  }
`;
const PricingTable = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 0.75rem;
  row-gap: 0.5rem;
  padding-bottom: 1rem;
  h4 {
    justify-self: start;
  }
`;
export default SponsorForm;
